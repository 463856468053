
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      lastTimeStamp: Date.now()
    };
  },
  computed: {
    ...mapState('alert', ['showDialog']),
    ...mapState('queue', ['stores', 'floorData', 'posListByStore'])
  },
  watch: {
    showDialog() {
      this.dialog = this.showDialog;
    }
  },
  async created() {
    await this.fetchUsers();
    await this.fetchUserGroup();
    await this.fetchUserGroupHierarchy();
    await this.fetchStoresList();
    if (this.storeID) {
      await this.fetchFloorListByStore(this.storeID);
    } else {
      await this.fetchFloorListByStore('1');
    }
    if (this.floorID) {
      await this.fetchPosListByStore(this.floorID);
    } else {
      await this.fetchPosListByStore('1');
    }
  },
  methods: {
    ...mapActions('alert', [
      'fetchUsers',
      'fetchUserGroup',
      'fetchUserGroupHierarchy'
    ]),
    ...mapActions('queue', [
      'fetchStoresList',
      'fetchFloorListByStore',
      'fetchPosListByStore'
    ]),
    refreshPage() {
      this.lastTimeStamp = Date.now();
    }
  } // methods
}; // export default
